<template>
  <div v-if="notification" class="qj-notification">
    <div class="qj-notification-detail" v-bind:class="getClass('type')">
      <p class="ma-0 overline grey--text text--darken-2">Tipo</p>
      <p>{{ notification.type || "-" | notificationType }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('active')">
      <p class="ma-0 overline grey--text text--darken-2">Ativo</p>
      <p>{{ notification.active | active }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('days')">
      <p class="ma-0 overline grey--text text--darken-2">Quantos dias</p>
      <p>{{ notification.days || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('days_type')">
      <p class="ma-0 overline grey--text text--darken-2">Tipo de dias</p>
      <p>{{ notification.days_type || "-" | daysType }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('send_next_business_day')">
      <p class="ma-0 overline grey--text text--darken-2">Enviar próximo dia útil</p>
      <p>{{ notification.send_next_business_day | sendNextBusinessDay }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('payment_method')">
      <p class="ma-0 overline grey--text text--darken-2">Método de pagamento</p>
      <p>{{ notification.payment_method || "-" | paymentMethod }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('payment_type')">
      <p class="ma-0 overline grey--text text--darken-2">Tipo de pagamento</p>
      <p>{{ notification.payment_type || "-" | paymentType }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('when')">
      <p class="ma-0 overline grey--text text--darken-2">Quando</p>
      <p>{{ notification.when || "-" | whenFilter }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('min_days_after_proposal_agreed')">
      <p class="ma-0 overline grey--text text--darken-2">Dias após o aceite da proposta</p>
      <p>{{ notification.min_days_after_proposal_agreed || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('min_days_until_due_date')">
      <p class="ma-0 overline grey--text text--darken-2">Dias até o vencimento</p>
      <p>{{ notification.min_days_until_due_date || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('sms')">
      <p class="ma-0 overline grey--text text--darken-2">SMS</p>
      <p>{{ notification.sms || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('flash_sms')">
      <p class="ma-0 overline grey--text text--darken-2">Flash SMS</p>
      <p>{{ notification.flash_sms || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('email_subject')">
      <p class="ma-0 overline grey--text text--darken-2">Assunto e-mail</p>
      <p>{{ notification.email_subject || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('email_asset_url')">
      <p class="ma-0 overline grey--text text--darken-2">URL - E-mail</p>
      <p>{{ notification.email_asset_url || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('push_title')">
      <p class="ma-0 overline grey--text text--darken-2">Titulo Notificação Push</p>
      <p>{{ notification.push_title || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('push_body')">
      <p class="ma-0 overline grey--text text--darken-2">Corpo Notificação Push</p>
      <p>{{ notification.push_body || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-bind:class="getClass('whatsapp_initiative')">
      <p class="ma-0 overline grey--text text--darken-2">Nome da iniciativa no Dodo</p>
      <p>{{ notification.whatsapp_initiative || "-" }}</p>
    </div>

    <div class="qj-notification-detail" v-if="notification.whatsapp_params" v-bind:class="getClassImageWhatsappParams()">
      <p class="ma-0 overline grey--text text--darken-2">URL - Imagem whatsapp</p>
      <p>{{ notification.whatsapp_params.file_url || "-" }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      default: null,
    },
    currentNotification: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  methods: {
    getClass: function(name) {
      if (this.notification == null) return;
      if (this.currentNotification == null) return;
      if (this.notification[name] != this.currentNotification[name]) {
        return 'different';
      } else {
        return '';
      }
    },
    getClassImageWhatsappParams(){
      if (!this.notification.whatsapp_params || !this.currentNotification.whatsapp_params) {
        return '';
      }

      if (this.notification.whatsapp_params.file_url != this.currentNotification.whatsapp_params.file_url){
        return 'different';
      } else {
        return '';
      }
    }
  }
};
</script>

<style>
.qj-notification {
  width: 50%;
}

.qj-notification-detail {
  padding: 8px 16px 2px;
}

.different {
  background-color: #B0C4DE;
  font-weight: bold;
}
</style>